function determine(property) {
  return function(injectedProps) {
    const {
      theme: { getColor },
      shade
    } = injectedProps;
    const prop = injectedProps[property];
    return `${property}: ${prop || getColor(shade, property === "color")};`;
  };
}

export { determine };
