import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { IconContext } from "react-icons";
import { useTheme } from "./ThemeState";
// import { useTheme } from "./theme-manager"

const GlobalStyles = createGlobalStyle`
  body {
    font-family: Roboto ;
  }
`;

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Theme = ({ children }) => {
  const { theme } = useTheme();

  return (
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </IconContext.Provider>
  );
};

//=============================
//==== 🔧🔧 HELPERS 🔧🔧 ====
//=============================

export default Theme;
