import React from 'react';
import styled from 'styled-components';
import SideNav from './SideNav';
import Main from './Main';

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Layout = ({ mainHeader, mainContent, mainContentOveride }) => {
	return (
		<Container>
			<SideNav id="side-nav" />
			<Main
				id="main"
				header={mainHeader}
				content={mainContent}
				contentOveride={mainContentOveride}
			/>
		</Container>
	);
};

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const NAV_WIDTH = 125;
const SLIDE_WIDTH = 300;
const HEADER_HEIGHT = 100;

const Container = styled.div`
	position: relative;
	#side-nav {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: ${NAV_WIDTH}px;
		@media only screen and (max-width: 768px) {
			width: 100%;
			top: inherit;
			bottom: 0;
			min-height: auto;
			height: auto;
			z-index: 3;
			* {
				border: 0;
			}
			svg {
				margin-bottom: 0;
			}
			.logo {
				display: none;
			}
		}
	}
	#main-header {
		position: fixed;
		width: 100%;
		@media only screen and (max-width: 768px) {
			left: 0;
			padding: 0 20px;
		}
		z-index: 1;
		top: 0;
		left: ${NAV_WIDTH}px;
		transition: 0.2s all ease-in-out;
		transform: translateX(
			${({ theme: { slideOutOpen } }) => (slideOutOpen ? SLIDE_WIDTH : 0)}px
		);
	}
	#main-content {
		position: absolute;
		z-index: 0;
		top: ${HEADER_HEIGHT}px;
		left: ${NAV_WIDTH}px;
		@media only screen and (max-width: 768px) {
			left: 0;
			width: 100%;
			padding: 20px;
		}
		transition: 0.2s all ease-in-out;
		transform: translateX(
			${({ theme: { slideOutOpen } }) => (slideOutOpen ? SLIDE_WIDTH : 0)}px
		);
		min-height: calc(100vh - ${HEADER_HEIGHT}px);
		width: ${({ theme: { slideOutOpen } }) =>
			`calc( 100% - ${slideOutOpen ? SLIDE_WIDTH + NAV_WIDTH : NAV_WIDTH}px)`};
		padding-bottom: 100px !important;
	}
`;

export default Layout;
