import React, { createContext, useContext, useState } from "react";
import { getToken, getUserFromToken, getUserProducts } from "./lib";
import Modal from "../Components/Login/Modal";
import createPersistedState from "use-persisted-state";
const useAuthState = createPersistedState("iable-auth");

const AuthContext = createContext();

// const ROLES = ["admin", "guest"];

const initialState = {
  user: { name: "guest", roles: ["guest"] }
};

function Auth({ children }) {
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [authState, setAuthState] = useAuthState({
    loggedIn: false
  });

  const login = async (username, password, onSuccess = () => {}) => {
    setLoading(true);

    const token = await getToken(username, password);
    const { data: user } = await getUserFromToken(token);
    const products = await getUserProducts(token, user.id);
    const payload = { user, token, products, loggedIn: new Date() };
    setAuthState(payload);
    onSuccess(payload);
    setLoading(false);
    setOpen(false);
    console.log("login complete");
  };
  const logout = () => {
    console.log("lout");
    setAuthState({ loggedIn: false });
  };

  function filterRoles(list) {
    const {
      user: { roles: userRoles }
    } = state;

    return list.filter(
      ({ roles }) => !roles || roles.some(role => userRoles.includes(role))
    );
  }

  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(<h1>Login</h1>);

  const [onSuccess, setOnSuccess] = useState({ fn: () => {} });
  async function ifSessionActive(action, failAction) {
    if (await isActive()) return action(authState);
    setOnSuccess({ fn: action });
    setContent(
      <h2>Please Log In to Refresh your session and complete your action</h2>
    );
    setOpen(true);
  }

  async function isActive() {
    const id = authState?.user?.id;
    const token = authState?.token;
    if (await getUserProducts(token, id)) return true;
    console.log("false");
    return false;
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        setState,
        filterRoles,
        login,
        logout,
        ifSessionActive,
        authState,
        open,
        setOpen,
        isActive,
        loading,
        setContent,
        setOnSuccess
      }}
    >
      <Modal
        isOpen={open}
        content={content}
        onDismiss={() => setOpen(false)}
        onSuccess={onSuccess.fn}
      />
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  return useContext(AuthContext);
}

export default Auth;
export { useAuth };
