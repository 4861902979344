import React from 'react';
import styled from 'styled-components';

import logo from '../../images/logo.png';

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Main = ({ id, header, content, contentOveride }) => {
	const customHeader = React.isValidElement(header);

	return (
		<Container id={id}>
			<Header id="main-header" isCustom={customHeader}>
				<Logo className="logo" as="img" src={logo} />
				{customHeader ? header : <h2>{header}</h2>}
			</Header>
			<Content id="main-content" isCustom={!!contentOveride}>
				{content || contentOveride}
			</Content>
		</Container>
	);
};

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Logo = styled.img`
	max-height: 70px;
	margin-right: 20px;
	display: none;
	@media (max-width: 768px) {
		display: block;
	}
`;
const Container = styled.div`
	width: 100%;
`;
const Header = styled.div`
	height: 100px;
	background: #00a9ff;
	color: #fff;
	display: flex;
	flex-direction: row;
	padding: 0 40px;
	align-items: center;
`;

const Content = styled.div`
	background: #eee;
	padding: 40px;
`;

export default Main;
