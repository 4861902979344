import React from "react";
import Theme from "./Theme";
import Routing from "./Routing";
import AppState from "./State";
import ThemeState from "./Theme/ThemeState";
import Auth from "./Auth";

function App() {
  return (
    <AppState>
      <ThemeState>
        <Theme>
          <Auth>
            <Routing />
          </Auth>
        </Theme>
      </ThemeState>
    </AppState>
  );
}

export default App;
