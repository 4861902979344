import React, { useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../Auth";
import Button from "../Cards/Button";
import Spinner from "../spinner";

const Login = ({ onSuccess }) => {
  const { login, loading } = useAuth();
  const [state, setState] = useState({
    username: "francois@bigbrave.co.za",
    password: "9rVm&1HVD6n6Z8WS)HA8)fwn"
  });
  const handleChange = ({ target: { name, value } }) =>
    setState(p => ({ ...p, [name]: value }));

  // console.log({ authState });

  return (
    <Container>
      <label>
        Username:
        <input value={state.username} onChange={handleChange} name="username" />
      </label>
      <label>
        Password:
        <input
          value={state.password}
          onChange={handleChange}
          name="password"
          type="password"
        />
      </label>
      <Button
        label={
          <>
            Login
            {loading && <Spinner small />}
          </>
        }
        fluid
        onClick={() => login(state.username, state.password, onSuccess)}
      />
      {/* <Button onClick={logout} label="Logout" /> */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  label {
    margin-bottom: 20px;
  }

  input {
    border-radius: 4px;
    margin-left: 10px;
    padding: 5px 10px;
    border: 1px solid grey;
  }
`;

export default Login;
