import React, { createContext, useContext, useState } from "react";
import { getThemeColors } from "./colors";

const ThemeContext = createContext();

const DEFAULT_COLOR = "grey-02";

function ThemeState({ children }) {
  const [theme, setTheme] = useState({
    ...getThemeColors(DEFAULT_COLOR),
    slideOutOpen: false
  });

  const toggleSlideOut = () =>
    setTheme(p => ({ ...p, slideOutOpen: !p.slideOutOpen }));

  const updateThemeColor = (color = DEFAULT_COLOR, contrast = false) =>
    setTheme(p => ({ ...p, ...getThemeColors(color, contrast) }));

  return (
    <ThemeContext.Provider
      value={{ theme, setTheme, toggleSlideOut, updateThemeColor }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

function useTheme() {
  return useContext(ThemeContext);
}

export default ThemeState;
export { useTheme };
