import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import Layout from '../../Layout';
import Grid from '../../Components/Grid';
import Loader from '../../Components/loader';
import PublicationCard from '../../Components/Cards/PublicationCard';

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Home = () => {
	const [products, setProducts] = useState([]);
	const [sites, setSites] = useState([]);
	const [cards, setCards] = useState([]);
	const [allContributors, setAllContributors] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const getToken = (siteSlug) => {
		return axios
			.post(
				`${
					process.env.REACT_APP_PUB_WP_URL
				}/${siteSlug}/wp-json/jwt-auth/v1/token`,
				{
					username: 'gatsby',
					password: 'V4scjSiljwyv#@pc#BbbjQJZ',
				}
			)
			.then((res) => {
				return res.data.token;
			});
	};

	useEffect(() => {
		setLoading(true);
		setError(false);

		let productInfo = `https://lacted.org/wp-json/wc/v2/products?category=${
				process.env.REACT_APP_PROD_CATEGORY_ID
			}&consumer_key=${process.env.REACT_APP_CONSUMER_KEY}&consumer_secret=${
			process.env.REACT_APP_CONSUMER_SECRET
		}`;

		axios
			.get(productInfo)
			.then(({ data, status }) => {
				setProducts(data);
				// Get correlating WP site options data on publication
				// NB Product slug and site slug must be the same
				if (status === 200) {
					data.map((site) => {
						getToken(site.slug).then((token) => {
							const config = {
								headers: {
									Authorization: `Bearer ${token}`,
								},
							};

							axios
								.all([
									axios.get(
										`${process.env.REACT_APP_PUB_WP_URL}/${
											site.slug
										}/wp-json/acf/v3/options/options`,
										config
									),
									axios.get(
										`${process.env.REACT_APP_PUB_WP_URL}/${
											site.slug
										}/wp-json/acf/v3/contributor`,
										config
									),
								])
								.then(
									axios.spread((getOptions, getContributors) => {
										if (getOptions.status === 200) {
											const data = getOptions.data.acf;
											setSites((site) => [...site, data]);
										}
										if (getContributors.status === 200) {
											const data = getContributors.data;
											setAllContributors((contributor) => [
												...contributor,
												...data,
											]);
										}
									})
								);
						});
					});
				}
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setError('Could not load data...');
				setLoading(false);
			});
	}, []);

	// Combine all the data we need for the cards
	useEffect(
		() => {
			let cardsToSet = products.map((product, i) => {
				let finalData;

				const site = sites.filter(
					(site) => parseFloat(site.product_id) === product.id
				)[0];

				if (site && site.publication_name) {
					finalData = {
						id: product.id || i,
						name: site.publication_name,
						overview: site.publication_overview,
						theme: site.theme,
						icon: site.publication_icon.url,
						isActive: site.is_active,
						buttons: [
							{
								label: `Visit ${site.publication_name}`,
								url: site.front_end_url,
							},
						],
						contributors: site.pub_contributors[0],
						isPublished: product.status === 'publish',
						isFeatured: product.featured,
						price: product.price_html,
					};
				}
				return finalData;
			});
			// Add LactFacts Mobile App
			cardsToSet = [
				...cardsToSet,
				{
					id: 9999,
					name: 'LactFacts Mobile App',
					overview: `LactFacts are very short summaries of our Clinical Questions. Each week we publish a LactFact along with the Clinical Question.
					We also provide the LactFacts mobile app as a great way to stay current on the move and find clinical evidence you need while with patients or clients. Need a fact to prove your point? Just whip out the LactFacts App! Now available on <a href="https://play.google.com/store/apps/details?id=org.lacted.lactfacts" target="_blank">Android</a> and <a href="https://itunes.apple.com/us/app/lactfacts/id1455008108" target="_blank">Apple</a>.`,
					theme: 'purple-01',
					icon:
						'https://appcontent.lacted.org/sandbox/wp-content/uploads/sites/2/2020/04/LactFacts-Logo-512-square.png',
					isActive: true,
					buttons: [
						{
							label: 'LactFacts for Apple Devices (iTunes)',
							url: 'https://itunes.apple.com/us/app/lactfacts/id1455008108',
						},
						{
							label: 'LactFacts for Android (Google Play)',
							url:
								'https://play.google.com/store/apps/details?id=org.lacted.lactfacts',
						},
					],
					contributors: {
						contributors_heading: 'Editors',
						contributors: [
							{
								ID: 18,
								post_title: 'Anne Eglash',
							},
						],
					},
					isPublished: true,
					isFeatured: false,
					price: 'FREE',
				},
			];

			setCards(cardsToSet);
		},
		[products, sites]
	);

	return (
		<Layout
			mainHeader={'Welcome to the IABLE App Hub'}
			mainContent={
				<Container>
					<Loader loading={loading || cards.includes(undefined)}>
						{!error ? (
							<Message className="info">
								Please select a publication below to enter into the specific
								publication.
							</Message>
						) : (
							<Message className="danger">{error}</Message>
						)}
						<Grid gap="40px" cols="1fr">
							{cards.map(
								(card, i) =>
									card &&
									card.isActive &&
									card.isPublished && (
										<PublicationCard
											card={card}
											key={i}
											allContributors={allContributors}
										/>
									)
							)}
						</Grid>
					</Loader>
				</Container>
			}
		/>
	);
};
export default Home;

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div``;
const Message = styled.div`
	margin-bottom: 30px;
	padding: 20px;
	border-radius: 8px;
	&.info {
		color: #026edb;
		border: 1px solid #98d8fb;
		background: #ccedfe;
	}
	&.danger {
		color: #da4652;
		border: 1px solid #ffb09f;
		background: #ffd0c0;
	}
`;
