import React from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import Login from "./index";
import Loader from "../loader";

const Modal = ({ isOpen, onDismiss, onSuccess, content }) => {

  return (
    <DialogOverlay
      style={{ background: "hsla(0, 100%, 100%, 0.9)", zIndex: 2 }}
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <DialogContent
        aria-label="content"
        style={{
          boxShadow: "0px 10px 50px hsla(0, 0%, 0%, 0.33)",
          textAlign: "center"
        }}
      >
        <Loader loading={false}>
          {content}
          <Login onSuccess={onSuccess} />
        </Loader>
      </DialogContent>
    </DialogOverlay>
  );
};

export default Modal;
