import React from 'react';
import styled from 'styled-components';
import { colors } from '../../Theme/colors';

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const PublicationCard = ({ card, allContributors }) => {
	const bgColor = colors[card.theme][500][0];
	const fgColor = colors[card.theme][500][1];

	const getContributor = (contributor) => {
		const current = allContributors.filter(
			(con) => con.id === contributor.ID
		)[0];

		return `<strong>${contributor.post_title}</strong> (${
			current?.acf?.qualifications
		})`;
	};
	return (
		<Card>
			<Container>
				<Left>
					<img
						src={
							card.icon ||
							'https://appcontent.lacted.org/galactapedia/wp-content/uploads/sites/7/2020/03/Globe-Logo.png'
						}
						alt=""
					/>
					<Label bgColor={bgColor} fgColor={fgColor}>
						<span dangerouslySetInnerHTML={{ __html: card.price }} />
					</Label>
				</Left>
				<Right bgColor={bgColor} fgColor={fgColor}>
					{card.isFeatured && <div className="featured">Featured</div>}
					<h2>{card.name}</h2>
					<p dangerouslySetInnerHTML={{ __html: card.overview }} />
					{card.buttons && (
						<div className="button-row">
							{card.buttons.map((button) => (
								<Button href={button.url} bgColor={bgColor} fgColor={fgColor}>
									{button.label} &#10142;
								</Button>
							))}
						</div>
					)}
					{card.contributors && (
						<div className="contributors">
							<span className="heading">
								{card.contributors.contributors_heading}:
							</span>
							{card.contributors.contributors.map((contributor, i) => (
								<span
									key={i}
									className="name"
									dangerouslySetInnerHTML={{
										__html: getContributor(contributor),
									}}
								/>
							))}
						</div>
					)}
				</Right>
			</Container>
		</Card>
	);
};

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div`
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 100px auto;
	position: relative;
	@media (max-width: 480px) {
		grid-template-columns: 1fr;
	}
`;
const Left = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	img {
		width: 100px;
		height: 100px;
		border-radius: 4px;
		margin-bottom: 10px;
	}
`;
const Right = styled.div`
	h2 {
		color: ${({ bgColor }) => (bgColor ? bgColor : '#3f434b')};
		margin: 15px 0 15px 0;
		font-size: 22px;
		text-decoration: none !important;
	}
	p {
		color: #3f434b;
		text-decoration: none !important;
		margin: 0;
		font-size: 15px !important;
		line-height: 1.3;
	}
	a {
		color: ${({ bgColor }) => (bgColor ? bgColor : '#3f434b')};
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.featured {
		background: #ffc107;
		color: #0b1317;
		position: absolute;
		top: -20px;
		right: -20px;
		padding: 6px 12px;
		border-radius: 0 0 0 10px;
	}
	.contributors {
		font-size: 15px !important;
		margin-top: 15px;
		border-top: 1px solid #eee;
		padding-top: 15px;
		.heading {
			color: #6a707d;
			padding-right: 5px;
		}
		.name {
			color: #6a707d;
			padding-right: 5px;
			strong {
				color: #3f434b;
			}
			&:after {
				content: ', ';
			}
			&:last-child:after {
				content: '';
			}
		}
	}
	.button-row {
		margin: 20px 0 0 0;
	}
`;
const Card = styled.div`
	text-decoration: none !important;
	border-radius: 8px;
	overflow: hidden;
	padding: 20px;
	background: #fff;
	transition: 0.2s all ease-in-out;
	box-shadow: 2px 14px 15px -8px #6d6d6d0d;
`;
const Button = styled.a`
	background: ${({ bgColor }) => (bgColor ? bgColor : '#3f434b')};
	color: ${({ fgColor }) => (fgColor ? fgColor : '#fff')} !important;
	padding: 10px 15px;
	margin: 5px 5px 5px 0;
	border-radius: 5px;
	font-weight: 600;
	min-width: 70px;
	text-align: center;
	display: inline-block;
	transition: all 200ms ease-in-out;
	text-decoration: none;
	&:hover {
		cursor: pointer;
		box-shadow: 2px 10px 15px -8px #6d6d6d4a;
		transform: translateY(-4px);
		text-decoration: none !important;
	}
`;
const Label = styled.div`
	background: #d8d8df;
	border: 1px solid #cdcdd6;
	display: block;
	color: #333;
	padding: 8px 12px;
	margin-top: 10px;
	border-radius: 5px;
	font-weight: 600;
	min-width: 70px;
	text-align: center;
	width: 100%;
`;

export default PublicationCard;
