import React from "react";
import styled from "styled-components";
import { determine } from "./util";
import { getNavProps } from "../../Utils";

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Button = ({
  to,
  onClick,
  href,
  label,
  icon,
  shade = 300,
  color,
  background,
  iconPosition = "right",
  fluid,
  hover,
  round
}) => {
  var props = getNavProps({ to, onClick, href });
  return (
    <Container
      {...{
        shade,
        color,
        background,
        iconPosition,
        fluid,
        hover,
        round,
        ...props
      }}
    >
      <Label>{label}</Label>
      {icon && <Icon {...{ shade, color, background }}>{icon}</Icon>}
    </Container>
  );
};

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================

const Icon = styled.span`
  padding: 10px;
  transition: 0.2s all ease-in-out;
  ${determine("background")}
  ${determine("color")}
  filter: brightness(0.9);
`;

const Container = styled.button`
  border-radius: ${({ round }) => (round ? "200px" : "4px")};
  border: 0;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  ${({ fluid }) => fluid && "width: 100%;"}
  flex-direction: ${({ iconPosition }) =>
    iconPosition === "right" ? "row" : "row-reverse"};
  ${determine("background")}
  ${determine("color")}
  transition: 0.2s all ease-in-out;
  &:hover{
      filter:brightness(1.1);
      ${Icon}{
        filter:brightness(1);
      }
      ${({ hover: h }) =>
        h && `transform: translate${h === "x" ? "X(3px)" : "Y(-3px)"};`}
  }
`;
const Label = styled.span`
  padding: 5px 10px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Button;
