import { NavLink } from "react-router-dom";

function getNavProps({ to, onClick, href }) {
  return to
    ? { as: NavLink, to }
    : onClick
    ? { as: "button", onClick }
    : { as: "a", href };
}

export { getNavProps };
