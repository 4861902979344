import React from 'react';
import styled from 'styled-components';

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const Grid = ({ gap = '', cols = '', rows = '', children }) => {
	return <Container {...{ gap, cols, rows }}>{children}</Container>;
};

//=============================
//==== 💅💅 STYLES 💅💅 =====
//=============================
const Container = styled.div`
	display: grid;
	grid-gap: ${({ gap }) => gap};
	grid-template-columns: ${({ cols }) => cols};
	grid-template-rows: ${({ rows }) => rows};

	@media only screen and (max-width: 960px) {
		grid-template-columns: repeat(1, 1fr);
	}
`;

export default Grid;
