import Axios from "axios";

// TODO
// refactor and use the auth fro the gatsby-publications

const objToFormBody = obj =>
  Object.keys(obj)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))
    .join("&");

async function getToken(username, password) {
  const payload = {
    grant_type: "password",
    username,
    password,
    client_id: "VD8pPcVgZUmFL1ILEuayuloLPHgGH2Q72xPuKJVJ",
    client_secret: "5PO9HkQhzOgQuSrlFmyAvgB5CnvkTjFoZ8aexHP9"
  };

  return new Promise((res, rej) => {
    const formBody = objToFormBody(payload);
    const url = "https://lacted.org?oauth=token"; // site that doesn’t send Access-Control-*
    Axios.post(url, formBody, {
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(({ data }) => {
        console.log({ data });
        res(data.access_token);
      })
      .catch(e => console.log({ e }));
  });
}

async function getUserFromToken(token) {
  return Axios.get(
    "https://lacted.org/wp-json/wp/v2/users/me?access_token=" + token
  );
}

async function getUserProducts(access_token, id) {
  const { data } = await Axios.get(
    "https://lacted.org/wp-json/wc/v3/orders?access_token=" +
      access_token +
      "&customer=" +
      id
  ).catch(e => {
    console.log({ e });
    return false;
  });

  return data?.flatMap(({ line_items }) => line_items);
}

export { getToken, getUserFromToken, getUserProducts };
