import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { Home } from '../Pages';

function Routing() {
	return (
		<Router>
			<Switch>
				<Route path="/" exact={true}>
					<Home />
				</Route>
			</Switch>
		</Router>
	);
}

export default Routing;
