import React from 'react';
import styled from 'styled-components';

import logo from '../../images/logo.png';
import { FaPodcast } from 'react-icons/fa';
import { FiCalendar, FiMessageCircle } from 'react-icons/fi';
import { TiDocument } from 'react-icons/ti';
import { AiOutlineTeam } from 'react-icons/ai';

//=============================
//=== 🧱🧱 COMPONENT 🧱🧱 ===
//=============================
const SideNav = ({ id }) => {
	return (
		<Container id={id}>
			<Logo className="logo" as="img" src={logo} />
			<nav>
				<NavItem
					href={`https://lacted.org/what-is-the-iable/`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<AiOutlineTeam size="35px" />
					About
				</NavItem>
				<NavItem
					href={`https://lacted.org/events/`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<FiCalendar size="35px" />
					Events
				</NavItem>
				<NavItem
					href={`https://lacted.org/podcasts/`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<FaPodcast size="35px" />
					Podcasts
				</NavItem>
				<NavItem
					href={`https://lacted.org/iable-resources-library/iable-breastfeeding-education-handouts/`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<TiDocument size="35px" />
					<span>Breastfeeding</span>
					Handouts
				</NavItem>
				<NavItem
					href="https://lacted.org/pwa-feedback/?pub=AppHub&subject=Feedback/Question%20about%20the%20App%20Hub"
					target="_blank"
					rel="noopener noreferrer"
				>
					<FiMessageCircle size="35px" />
					Feedback
				</NavItem>
			</nav>
		</Container>
	);
};

//=============================
//==== 💅💅 STYLES 💅💅 ====
//=============================

const Container = styled.div`
	width: 100px;
	display: flex;
	background: #0098e6;
	flex-direction: column;
	min-height: 100vh;
	nav {
		display: flex;
		flex-direction: column;
	}
	@media only screen and (max-width: 768px) {
		nav {
			display: grid;
			font-size: 14px !important;
			grid-template-columns: repeat(5, 1fr);
			a {
				border: 0;
				display: grid;
				grid-template-rows: 35px auto;
				grid-gap: 5px;
				justify-items: center;
				align-items: center;
				span {
					display: none;
				}
			}
			svg {
				width: 25px;
				height: 25px;
			}
		}
	}
`;

const Logo = styled.div`
	height: 100px;
	background: #fff;
	padding: 10px 18px;
`;

const NavItem = styled.a`
	background: #0087cc;
	color: #fff;
	border: none;
	border-top: 1px solid #00a9ff;
	border-bottom: 1px solid #00a9ff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100px;
	text-decoration: none;
	text-align: center;
	padding: 10px;
	transition: 0.2s all ease-in-out;
	&.active,
	&:hover {
		background: #0098e6;
	}
	svg {
		transition: 0.2s all ease-in-out;
		margin-bottom: 10px;
	}
	&:hover {
		svg {
			transform: scale(1.4);
		}
	}
	@media only screen and (max-width: 768px) {
		border-left: 1px solid #00a9ff !important;
		min-height: 70px;
	}
`;

export default SideNav;
